<template>
  <div>
    <load-profile></load-profile>
    <bet-setting-menu></bet-setting-menu>

    <!-- Table Container Card -->
    <b-card>
      <validation-observer ref="updateForm" #default="{ invalid }">
        <!-- form -->
        <n-form-confirmation
          key="updateForm"
          @submit="submit"
          :form="$refs.updateForm"
          :disabled="invalid"
        >
          <n-input :fields="fields" v-model="data" :key="`input-${key}`">
          </n-input>
          <b-row>
            <b-col cols="12" class="text-right">
              <n-button-loading
                type="submit"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-1 mr-1"
                :loading="loading"
                :disabled="invalid"
                v-if="$can('create', resource)"
              >
                {{ $t("button.saveChanges") }}
              </n-button-loading>
              <n-button-loading
                type="button"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                :loading="loading"
                class="mt-1"
                @submit="back"
              >
                {{ $t("button.back") }}
              </n-button-loading>
            </b-col>
          </b-row>
        </n-form-confirmation>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { BFormGroup, BRow, BCol, BCard } from "bootstrap-vue";
import Repository from "@/repositories/RepositoryFactory";
import Ripple from "vue-ripple-directive";
import NButtonLoading from "@/components/NButtonLoading";
import NFormConfirmation from "@/components/NFormConfirmation";
import NInput from "@/components/NInput";
import FormInput from "./formInput";
import BetSettingMenu from "@/views/betSetting/BetSettingMenu.vue";

const ToolRepository = Repository.get("tool");

export default {
  components: {
    BFormGroup,
    BRow,
    BCol,
    BCard,
    NButtonLoading,
    NFormConfirmation,
    NInput,
    BetSettingMenu,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      key: 1,
      data: {
        betToolEn: null,
        betToolKh: null,
        status: true,
      },
      loading: false,
    };
  },
  mounted() {
    this.show();
  },
  methods: {
    show() {
      this.loading = true;
      ToolRepository.show(this.$route.params.id)
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.data = {
              ...data,
            };
            this.key++;
          }
        })
        .catch((error) => {})
        .then(() => {
          this.loading = false;
        });
    },
    submit() {
      this.$refs.updateForm.validate().then((success) => {
        if (success) {
          this.loading = true;
          ToolRepository.update(this.$route.params.id, this.data)
            .then((response) => {
              this.back();
            })
            .catch((error) => {
              if (error.response?.status == 422) {
                this.$refs.updateForm.setErrors(error.response?.data?.errors);
              }
            })
            .then(() => {
              this.loading = false;
            });
        }
      });
    },
    back() {
      this.$router.push({
        name: `list-${this.route}`,
      });
    },
  },
  setup() {
    const fields = [...FormInput];
    const resource = "bet-setting";
    const route = "tool";

    return { fields, resource, route };
  },
};
</script>